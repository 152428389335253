// -----------------------------------------------------------------------------------------------------
// @ Material inputs
// -----------------------------------------------------------------------------------------------------
@import "@ng-select/ng-select/themes/material.theme.css";

.pr-6 {
    padding-right: 6px !important;
}

.ml-6 {
    margin-left: 6px !important;
}

.b-c {
    padding: 6px 19px 2px 12px !important;
}

.p-h {
    padding: 16px 12px 27px 24px !important;
}

.p-17 {
    padding: 17px !important;
}

.p-sd {
    padding: 19px 15px !important;
}

.p-d-v {
    padding-bottom: 6px !important;
}

.b-c {
    margin-top: -76px;
}

.h-i-c {
    height: 30px !important;
    width: 30px !important;
    margin-top: 2px;
}


.mat-mdc-form-field-flex>.mat-mdc-form-field-infix {
    padding: 12px 0px !important;
}

.mat-mdc-form-field-flex {
    & .mat-mdc-form-field-icon-suffix {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 0 4px !important;
        font-size: 12px !important;

        .mat-mdc-icon-button {
            width: 34px !important;
            height: 34px !important;
        }
    }
}

.mat-mdc-form-field-infix>.clear-button {
    position: absolute !important;
    right: 8px !important;
    top: 4px !important;
    width: 30px !important;
    height: 30px !important;
}

.mat-mdc-form-field-flex>.mat-mdc-form-field-infix .mat-mdc-select-trigger {
    height: 14px !important;
}

.mat-mdc-form-field-flex>.mat-mdc-form-field-infix .mat-mdc-select {
    height: 14px !important;
}

.mat-mdc-form-field {
    font-size: 13px !important;
    flex-direction: column !important;

    & .mat-mdc-form-field-subscript-wrapper {
        margin-top: 0.6px !important;
        font-size: 75% !important;
        color: rgba(85, 85, 85, 0.6) !important;

        & .mat-mdc-form-field-bottom-align {
            height: 14px !important;
            line-height: 0% !important;
            font-weight: 500 !important;
        }

        & .mat-mdc-form-field-bottom-align::before {
            content: "";
            display: inline-block !important;
            height: 14px !important;
            vertical-align: middle !important;
        }

    }

    & .mdc-notched-outline__notch {
        border-right: 1px solid transparent !important;
    }
}

.mat-mdc-form-field .mdc-floating-label.mdc-floating-label--float-above {
    transform: translateY(-28px) scale(0.75) !important;
}

// .mdc-notched-outline__trailing {
//     border-left: 0 !important;
//     border-left-color: transparent !important;
// }

.mat-mdc-floating-label {
    top: 16px !important;
}


.mat-mdc-form-field-label-wrapper {
    top: -1.5em;
}

.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
    transform: translateY(-1.1em) scale(0.75);
    width: 133.33333%;
}

input.mat-mdc-input-element,
.mat-mdc-form-field:not(.mat-mdc-form-field-appearance-legacy) .mat-mdc-form-field-suffix .mat-icon {
    position: relative;
    bottom: 1px;
    height: 19px;
}

.mat-mdc-form-field-hide-placeholder .mat-mdc-form-field-label {
    position: absolute;
    top: 23px;
}

.mat-mdc-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-infix .mat-mdc-select-trigger {
    position: relative;
    bottom: 3px;
}

.mat-mdc-tab-body-wrapper {
    height: 100% !important;
}

.ng-dropdown-footer {
    padding: unset !important;
    line-height: unset !important;
    min-height: unset !important;
}

.ng-arrow-wrapper {
    display: flex;
    height: 100%;
    // margin-top: 7px;
    flex-direction: row;
    align-items: center;
}

.ng-select .ng-select-container {
    overflow: visible !important;
    height: 38px !important;
    align-items: center !important;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
    border-color: #f44336;
}

.ng-select.ng-invalid.ng-touched .ng-select-container.ng-appearance-outline:after {
    border: 2px solid #f44336;
}

.ng-select-container {
    position: relative;

    .mat-mdc-form-field-error {
        height: 0;
        position: absolute;
        font-size: 13px;
        bottom: 15px;
        left: 5px;
    }
}

.ng-select .ng-select-container.ng-appearance-outline .ng-placeholder {
    background-color: #fff !important;
    position: absolute;
    top: 16px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    background-color: #fff !important;
    font-size: 16px;
    top: 15px;
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.mat-menu-item {
    display: flex !important;
    align-items: center !important;
    gap: 6px !important;
    padding: 5px !important;
    width: 100% !important;

    &:hover {
        background-color: rgba(211, 211, 211, 0.313) !important;
    }

    &:active {
        background-color: rgba(169, 169, 169, 0.313) !important;
    }
}

.mat-mdc-raised-button,
.mdc-button {

    .mat-icon {
        margin-top: -2px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 0 5px !important;
        display: inline-flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .mdc-button__label {
        /* position: absolute; */
        // margin-bottom: -3px;
        text-wrap: nowrap !important;
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    display: flex !important;
    align-items: center !important;

    .option-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.ng-select .ng-select-container.ng-appearance-outline {
    overflow: unset !important;
    max-height: 44px !important;
    height: 24px !important;
    min-height: 44px !important;
}

.ng-select {
    // transform: translateY(-5px) !important;
    // margin-top: -11px;
    position: relative !important;
    top: -6px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.87);
    height: 22px;
}

.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after {
    border-color: rgba(0, 0, 0, 0.87);
}

.ng-select .ng-select-container.ng-appearance-outline:hover:after {
    border-color: rgba(0, 0, 0, 0.87);
    border-width: 1px !important;
}

.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after {
    border-color: rgba(0, 0, 0, 0.87);
}

.ng-select .ng-select-container.ng-appearance-outline:after {
    border-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-button-persistent-ripple.mdc-fab {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.mat-mdc-slide-toggle {
    color: inherit !important;
}

.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
    color: rgba(0, 0, 0, 0.87);
}

.ng-select.ng-select-focused.ng-invalid.ng-touched .ng-select-container .ng-value-container .ng-placeholder {
    color: rgba(0, 0, 0, 0.87);
}

.ng-select.ng-invalid.ng-touched .ng-select-container .ng-value-container .ng-placeholder {
    color: #f44336;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
    max-width: 100% !important;
    position: relative;
    //    top: -3px;
    // right: 15px;
}


.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    overflow: hidden;
}

//// selection unique

.ng-select .ng-spinner-loader {
    position: relative;
    top: 6px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container {

    .ng-value {
        position: relative;
        // position: absolute;
    }
}

.ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-arrow-wrapper {
    bottom: -4px !important;
}

.ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-clear-wrapper {
    bottom: 6px !important;
}

// .ng-select.ng-select-opened .ng-select-container.ng-appearance-outline .ng-placeholder {
//     position: absolute;
//     top: 19px;
// }

// .ng-select.ng-select-placeholder .ng-select-container.ng-appearance-outline .ng-placeholder {
//     position: absolute;
//     top: 20px;
// }

.ng-select.ng-select-single .ng-select-container .ng-value-container {
    overflow: visible !important;
    width: 80%;

    // .ng-value {
    //     span:first-child {
    //         display: none !important;
    //     }
    // }
}

.ng-select .ng-select-container {

    .ng-clear-wrapper {
        bottom: auto !important;
    }

    .ng-value-container {
        padding-right: 3px;
        margin-left: 5px;

        .ng-input {
            right: 0 !important;
            left: auto !important;
            // top: auto !important;
            bottom: auto !important;
            margin: 0 !important;
            padding: 0 !important;

            input {
                padding-right: 0;
                // right: 8px !important;
            }
        }
    }
}

.ng-select.products-ref-input {
    .ng-dropdown-panel {
        width: 500px !important;
    }
}

.mat-horizontal-content-container {
    overflow: visible !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    font-size: 13px;
}

.ng-dropdown-panel.ng-select-bottom {
    margin-bottom: 40px;
    top: auto;
}

.ng-dropdown-panel.ng-select-top {
    bottom: 62px;
}

.mat-mdc-select-panel {
    position: relative;
    top: 13px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    height: 25px !important;
    max-height: 25px !important;
    padding: 2px 10px !important;
    border-radius: 20px !important;
    background-color: #dbeafe;
    opacity: 1;
    transition: transform 0.5s;
    color: #1e60cb !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;

    & .ng-value-icon {
        color: inherit !important;
    }
}

.mat-mdc-option {
    min-height: 40px !important;
    line-height: 2.5em !important;
    height: 2.5em !important;
    padding: 0 !important;
    padding-left: 16px !important;

    & .mdc-list-item__primary-text {
        font-size: inherit !important;
        display: inline-block !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;

        & img {
            position: relative !important;
            top: -1px !important;
            display: inline-block !important;
        }
    }

    & .mat-pseudo-checkbox {
        margin: 0 6px !important;
    }
}

.mat-mdc-autocomplete-panel {
    padding: 0 !important;
}

.mat-mdc-autocomplete-panel.autocomplete-select {
    overflow: hidden !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
    padding: 0 !important;
}

.mat-mdc-slider {
    margin-right: 6px;
}

.mat-mdc-form-field-flex {
    height: 38px;
}

.filter-drop-down-icon {
    svg {
        width: 10px;
        height: 10px;
    }
}

.mat-mdc-tab-label {
    margin-left: 25px !important;
}

.mat-mdc-tab-list {
    flex-grow: unset !important;
}

.mat-grid-tile {
    overflow: visible !important;
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
    padding-top: 0.55em !important;
}

.customSearchPlaceholder {
    max-width: 0px;
    max-height: 0px;
    overflow: visible;
    color: gray;
    position: relative;
    top: 3px;
}

.mat-mdc-form-field-error {
    height: 30px;
    font-size: 11px;
    width: 100%;
    text-align: right;
}

.text-area .mat-mdc-form-field-flex {
    height: unset !important;
    min-height: 45px !important;
}

.text-areack .mat-form-field-flex {
    height: unset !important;
    min-height: 100px !important;
}

.mat-icon {
    text-align: center;
    padding-top: 1px;
}


.mat-mdc-form-field:not(.mat-mdc-form-field-disabled) {

    .mask-btn-input:not(.unit-active-item) .mdc-button {
        color: #5a5a5a !important
    }
}

body.theme-default .mat-mdc-input-element:disabled,
body.theme-default .mat-mdc-form-field-type-mat-native-select.mat-mdc-form-field-disabled .mat-mdc-form-field-infix::after {
    color: #585858;
}

::ng-deep.mat-mdc-input-element:disabled,
body.theme-default .mat-mdc-form-field-type-mat-native-select.mat-mdc-form-field-disabled .mat-mdc-form-field-infix::after {
    color: #DEDEDE !important;
}

.mat-form-field-infix {
    .input-clear-btn {
        position: absolute;
        right: 8px;
        bottom: 1px;
    }
}

.mat-mdc-menu-item {
    .mdc-list-item__primary-text {
        div {
            display: flex !important;
            align-items: center !important;
        }
    }
}