// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";
@import "../node_modules/@ng-select/ng-select/themes/material.theme.css";

@import "all";

@tailwind base;
@tailwind components;
@tailwind utilities;

app-pages,
app-clients,
app-view-all-clients,
app-view-all-prices,
app-view-all-clientFamily,
app-quote,
app-add-quote,
app-view-all-quote,
app-view-all-bill,
app-view-all-deliveries-note,
app-add-delivery-note,
app-account-presentation,
app-add-invoice,
app-dashboard,
app-assets,
app-view-all-assets,
app-add-return-voucher,
app-add-receipt-voucher,
app-add-asset,
app-view-all-providers,
app-client-header-info,
app-view-all-product-family,
app-view-all-return-vouchers,
app-view-all-receipt-vouchers,
app-products-container-per-documents,
app-view-all-receipt,
app-view-all-provider-invoices,
app-add-provider-invoice,
app-view-all-cashing,
app-view-all-return-provider,
app-view-all-bdc-providers,
app-add-bdc-providers,
app-add-return-provider,
app-view-all-disbursments,
app-view-all-deposits,
app-view-single-deposit,
app-funding-box,
app-view-all-funding-boxs,
app-view-all-inventory,
app-inventory,
app-view-single-inventory,
app-add-teams,
app-add-conting-sheet,
app-inventory-edit-mode,
app-matching-mode,
app-add-product-to-counting-sheet,
app-view-all-entry-vouchers,
app-add-entry-voucher,
app-view-all-exit-vouchers,
app-add-exit-voucher,
app-view-all-transfer-voucher,
app-add-transfer-voucher,
app-counting-sheet-print,
app-view-all-cash-funds,
app-admins,
app-add-admin,
app-view-admin,
app-users,
app-statistics,
app-all-statistics,
app-statistics-filters,
app-add-phone-number,
app-view-all-process,
app-add-process,
app-view-all-executions,
app-add-execution,
app-view-all-reparations-orders,
app-add-reparation-order,
app-add-intervention,
app-view-all-interventions,
app-view-all-technicians,
app-add-technician,
app-view-all-vehicles,
app-serial-numbers-popup,
app-not-in-stock-serail-numbers-popup,
app-serial-numbers-tracking-popup,
app-view-all-order-vouchers,
app-add-order-voucher,
app-add-notification,
app-add-damage,
app-view-all-damages,
app-damages {
  width: 100%;

  .h-100 {
    height: 90px !important;
    min-height: 90px !important;
    max-height: 90px !important;
    font-weight: 600 !important;
    color: white;
  }

  .h-70 {
    height: 70px !important;
    min-height: 70px !important;
    max-height: 70px !important;
    font-weight: 600 !important;
    color: white;
    font-size: 28px;
  }

  // div{
  //     font-size: 13px;
  // }

  .header-font-size {
    font-size: 25px;
    font-weight: 600;
    color: white;
    display: flex;
    gap: 8px;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .accent-color {
    color: #039be5 !important;
  }

  .mat-mdc-simple-snack-bar {
    font-size: 14px !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    padding: 0 4px !important;
    margin-top: 3px !important;
  }

  .mat-mdc-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      width: auto !important;
      line-height: normal;
    }
  }

  .mat-mdc-form-field,
  .mat-mdc-radio-button,
  .mat-checkbox,
  .ng-select {
    font-size: 14px;
  }

  app-view-all-products,
  app-view-single-folder {
    width: 100%;
  }

  .card-title {
    background-color: $secondary-color !important;
  }

  .clear-list-btn {
    font-size: 13px;
    color: #757575;
  }

  .bottom-buttons-container-basic {
    height: 55px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    align-items: center;
    margin-right: 15px;
    text-align: right;
  }

  .make-it-slow {
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 50%;
  }

  /* Transition to a bigger shadow on hover */
  .make-it-slow:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  /* The fast way */
  .make-it-fast {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }

  /* Pre-render the bigger shadow, but hide it */
  .make-it-fast::after {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  /* Transition to showing the bigger shadow on hover */
  .make-it-fast:hover::after {
    opacity: 1;
  }
}

app-product-division {
  .quantity {
    .quantity-global {
      p {
        margin: 14px 0 !important;
      }
    }
  }
}

.bubble-text {
  background: $bubble-color;
  color: #fff;
  padding: 0px 15px;
  border-radius: 8px;
  width: 350px;
  position: absolute;
  text-align: center;
  font-weight: 600;

  p {
    margin: 13px !important;
  }
}

.nav-link {
  color: #adadad !important;
  font-size: 14px;
  font-weight: 600;
}

.bubble-text::after {
  content: "";
  border-left: 4px solid transparent;
  border-right: 14px solid transparent;
  border-top: 20px solid $bubble-color;
  position: absolute;
}

.accent2 {
  background-color: #1f2538 !important;
  color: white !important;
}

.nav-link-icon {
  background-color: #1f9cff;
  height: 27px !important;
  width: 27px !important;
  min-height: 27px !important;
  min-width: 27px !important;
  line-height: 27px;
  border-radius: 5px;
  margin-top: 4px;
  font-size: 20px !important;
  color: white;
  padding: 5px 3px;
}

.accent2 .nav-link-icon {
  color: white !important;
}

.nav-collapsable > .children .nav-link-icon {
  background-color: unset;
  // color: #a7b1c2 !important;
}

.nav > .nav-group > .group-items > .nav-collapsable.open {
  background-color: #1f2538 !important;
}

.bouton-footer {
  // position: fixed;
  // bottom: 2px;
  // right: 6px;
  height: 48px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  align-items: center;
  margin-right: 10px;
  text-align: right;

  // background-image: linear-gradient( to bottom, rgba(30, 33, 41, 0) 0%, rgba(30, 33, 41, 0) 28%, rgba(30, 33, 41, 0.65) 100%);
  .right-container {
    position: fixed;
    right: 25px;
  }
}

.p-g {
  padding: 28px 12px !important;
}

.sidebar-title-style {
  white-space: nowrap;
  font-size: 18px;
  font-weight: 800;
  margin-left: 20px;
  color: $primary-color !important;
}

.sirh-add-btn {
  background-color: #039be5 !important;
  color: white !important;
}

.sirh-cancel-btn {
  background-color: #f44336 !important;
  color: white !important;
}

.sirh-validate-btn {
  background-color: #039be5 !important;
  color: white !important;
}

.sirh-delete-btn,
.sirh-deny-btn {
  background-color: #f44336 !important;
  color: white !important;
}

.sirh-edit-btn {
  background-color: #039be5 !important;
  color: white !important;
}

.custom-dialog-container .mat-mdc-dialog-container {
  padding: 0px !important;
  border-radius: 15px !important;

  .header {
    // border-radius: 15px 15px 0 0 !important;
  }

  & .mdc-dialog__container,
  & .mat-mdc-dialog-surface {
    border-radius: 15px !important;
    overflow-x: visible;
    overflow-y: auto;
  }
}

.custom2-dialog-container .mat-mdc-dialog-container {
  padding: 0px !important;
  border-radius: 15px !important;

  .header {
    // border-radius: 15px 15px 0 0 !important;
  }

  & .mdc-dialog__container,
  & .mat-mdc-dialog-surface {
    // height: fit-content;
    border-radius: 15px !important;
    overflow-x: visible;
    overflow-y: auto;
  }
}
.custom3-dialog-container {
  position: static;
  margin: auto !important;
  border-radius: 15px !important;

  & .mdc-dialog__container,
  & .mat-mdc-dialog-surface {
    border-radius: 15px !important;

  }
}
.custom-dialog-container-statistics .mat-mdc-dialog-container {
  min-height: 375px !important;
  background-color: #f4f4f4 !important;
  border-radius: 15px !important;
  backdrop-filter: blur(2px);

  .header {
    // border-radius: 15px 15px 0 0 !important;
  }
}

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

.mat-mdc-icon-button {
  font-size: inherit !important;
  padding: 5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

// styling of intl-tel-input component
.iti {
  display: block !important;
  height: 38px;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
  border: 2px solid #000 !important;
  border-radius: 4px;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input input {
  // margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #989899;
  border-radius: 4px;
  letter-spacing: 1.5px;
  // font-size: 18px;
  width: 100%;
  height: 38px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #eb311d;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input.ng-invalid.ng-touched input:focus {
  outline: none !important;
  border: 2px solid;
  border-color: #eb311d;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border: 2px solid;
  border-color: #000;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

ngx-intl-tel-input .container {
  background-color: red;
}

/// end ///
///

.mat-mdc-menu-content {
  padding: 0 !important;
}

.width-100 {
  width: 100% !important;
}

::ng-deep .iti__flag-container {
  .iti__country-list {
    right: -375.641px !important;
    left: auto !important;
  }
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
  > .mat-mdc-tab-header
  .mat-mdc-tab {
  height: 35px !important;
}

.info-line {
  .title {
    display: flex;
    align-items: center !important;

    span {
      display: flex !important;
      align-items: center !important;
    }
  }
}

// .fuse-sidebar[name="billing-right-sidebar"].rtl-direction,
// .fuse-sidebar[name="billing-quote-right-sidebar"].rtl-direction {
//   border-radius: 0 7px 7px 0 !important;
// }

// .fuse-sidebar-header.rtl-direction {
//   border-radius: 0 7px 0 0 !important;
// }

.ng-select .ng-select-container.ng-appearance-outline:after {
  height: 38px !important;
}

/// ------------- Begin RTL direction styles -------------
.rtl-direction {
  .bottom-buttons-container-admin,
  .bottom-buttons-container-user {
    .r-button {
      margin-left: 0 !important;
      margin-right: 15px !important;
    }

    .l-button {
      margin-right: 0 !important;
      margin-left: 15px !important;
    }

    .mat-icon {
      transform: rotate(180deg) !important;
    }
  }

  .datatable-header-cell-label,
  .datatable-body-cell-label {
    text-align: start !important;
  }

  .datatable-header-cell-template-wrap {
    .sort-btn {
      transform: rotate(180deg) !important;
    }
  }

  fuse-widget {
    .row-c3-content {
      display: flex !important;
      align-items: center !important;

      .mat-icon {
        transform: rotate(180deg) !important;
      }
    }
  }

  app-dz-detailed-currency {
    .add-currency-type-btn-container {
      .mat-icon {
        margin-left: 94px !important;
        margin-right: 0 !important;
      }
    }
  }

  .mat-form-field-label-wrapper {
    .mat-form-field-label {
      mat-label {
        overflow: visible !important;
      }
    }
  }

  app-view-single-process,
  app-view-single-execution {
    .text-title,
    .text {
      margin-left: 0 !important;
      margin-right: 16px !important;
    }
  }

  .header-title {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }

  app-associate-docs {
    .bouton-footer {
      .bottom-buttons-container-basic {
        margin-left: 16px !important;
        margin-right: 0 !important;
      }
    }
  }

  .bouton-footer {
    position: fixed;
    bottom: 5px;
    right: auto;
    left: 10px;
  }

  app-text-status-datatable {
    .status-icon-container {
      .mat-icon {
        margin-left: 6px !important;
        margin-right: 0 !important;
      }
    }
  }

  .card-title {
    margin-left: 0 !important;
    margin-right: 16px !important;
  }

  .nav-collapsable:has(:not(.open)) .nav-link .collapsable-arrow {
    transform: scaleX(-1) !important;
  }

  .nav-collapsable.open > .nav-link .collapsable-arrow {
    transform: rotate(90deg) !important;
  }

  .create-folder-option-img {
    margin-right: 0 !important;

    @media screen and (min-width: 600px) {
      margin-left: 8px !important;
    }
  }

  .create-folder-option-no-image {
    margin-left: 5px !important;
    margin-right: 0 !important;

    div {
      margin-left: 5px !important;
      margin-right: 0 !important;
    }
  }

  .fuse-sidebar {
    border-radius: 0 7px 7px 0 !important;

    .fuse-sidebar-header {
      border-radius: 0 7px 0 0 !important;
    }
  }

  .mat-error {
    text-align: left !important;
    direction: rtl !important;
  }

  app-view-single-client {
    .card-1 {
      .info-line {
        margin-left: 0 !important;
        margin-right: 14px !important;
      }

      .info {
        margin-right: 14px !important;
        margin-left: 10 !important;

        ul {
          margin-left: 0 !important;
          margin-left: -20px !important;
        }
      }
    }

    .image-top-container {
      margin-right: 0 !important;
      margin-left: 29px !important;
    }
  }

  .section-title {
    text-align: right !important;
  }

  .status-docs .mat-button-wrapper .status-icon .mat-icon {
    // right: -21px !important;
  }

  .status-docs {
    .mdc-button__label {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  app-view-single-product,
  app-view-single-deposit {
    .text-form {
      text-align: right !important;
    }

    .sirh-btn-header .mat-button-wrapper mat-icon {
      transform: rotate(180deg) !important;
    }
  }

  .mat-form-field-infix,
  .mat-mdc-form-field-icon-suffix {
    .input-clear-btn,
    .clear-input {
      position: absolute;
      bottom: 1px;
      right: auto !important;
      left: 8px !important;
    }
  }

  .header {
    .image-container {
      margin-right: auto !important;
      margin-left: 15px !important;
    }
  }

  .mat-mdc-option {
    padding-left: 0 !important;
    padding-right: 16px !important;
  }

  .mat-mdc-form-field {
    .mat-mdc-form-field-infix {
      .clear-button {
        right: auto !important;
        left: 8px !important;
      }
    }
  }

  .filter-drop-down-icon {
    margin-right: 20px !important;
    margin-left: 8 !important;
  }

  .fuse-sidebar-header
    .fuse-sidebar-header-container
    .fuse-sidebar-header-right
    .status-docs
    .mdc-button__label {
    right: -29px !important;
  }

  .mat-toolbar {
    .user-button {
      .mdc-button__label {
        div {
          .avatar {
            margin-left: 8px !important;
            margin-right: 0 !important;
          }

          .username:nth-child(2) {
            margin-left: 4px !important;
            margin-right: 0 !important;
          }

          .username:nth-child(3) {
            margin-left: 12px !important;
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  .ng-select {
    .ng-dropdown-panel {
      left: auto !important;
      right: 0 !important;
    }
  }

  app-add-reparation-order {
    .bottom-buttons-container-basic {
      justify-content: start !important;

      .right-container {
        right: auto !important;
        left: 25px !important;
      }
    }
  }

  .ng-select .ng-select-container {
    .ng-value-container {
      padding-right: 3px !important;
      margin-right: 5px !important;

      .ng-input {
        input {
          padding-right: 0 !important;
          // right: 16px !important;
        }
      }
    }
  }

  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    max-width: calc(100% - 80px) !important;
    top: 19px;
    right: 16px !important;
  }

  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-value {
    right: 0 !important;
    left: auto !important;
  }
}

/// ------------- End RTL direction styles -------------

/// ------------- Begin LTR direction styles -------------
.ltr-direction {
  .create-folder-option-img {
    margin-right: 0 !important;

    @media screen and (min-width: 600px) {
      margin-right: 8px !important;
    }
  }

  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input,
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-input {
    max-width: calc(100% - 80px) !important;
    top: 16px !important;
    left: 16px !important;
  }

  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-value {
    right: auto !important;
    left: 0 !important;
  }
}

.nav .nav-item .nav-link:hover {
  background-color: #ffffff21;
  color: white !important;
}

.warning-red-dot {
  width: 14px;
  height: 14px;
  background-color: red;
  border-radius: 50%;
}

.warning-icon {
  width: 14px;
  height: 14px;
  background-color: red;
  border-radius: 50%;
}

.custom-mini-fab {
  box-shadow: none;
  background-color: #272d3f;
  color: whitesmoke;
}

.custom-mini-fab:hover {
  background-color: whitesmoke;
  color: #272d3f;
}

/// ------------- End LTR direction styles -------------
